<template>
  <div class="flex justify-end mb-4">
    <asom-button text="Add Station" variant="success" icon="user-add" @click="
     $router.push({
      name: 'addUserStation'
    })" />
  </div>
  <asom-alert v-if="error" variant="error" :error-message="error" />
  <div class="w-full text-center" v-if="isLoading">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <asom-client-table :data="sccfList"  :filterable="false" :columns="['no', 'EffectiveFrom', 'Line', 'Station', 'DateCreated', 'Edit']">
    <template v-slot:header_EffectiveFrom>Effective From</template>
    <template v-slot:no="scopedSlots" >
      {{ scopedSlots.rowIndex + 1 }}
    </template>
    <template v-slot:EffectiveFrom="scopedSlots">
      {{ displayUtcDate(scopedSlots.rowData.dateEffectiveFrom) }}
    </template>
    <template v-slot:Line="scopedSlots">
      {{ scopedSlots.rowData.lineName }}
    </template>
    <template v-slot:Station="scopedSlots">
      {{ scopedSlots.rowData.stationName }}
    </template>
    <template v-slot:DateCreated="scopedSlots">
      {{ displayUtcDate(scopedSlots.rowData.dateCreated) }}
    </template>
    <template v-slot:Edit="scopedSlots">
      <asom-button
        text="Edit"
        @click="$router.push({
          name: 'editUserStation',
          params: scopedSlots.rowData
        })"
      />
    </template>
  </asom-client-table>
</template>

<script>
import { displayUtcDate } from '@/helpers/dateTimeHelpers'
import get from "lodash.get";
import isEmpty from 'lodash.isempty';
import { listUserRoles } from "@/services/user.service";


export default {
  name: "Users",
  data() {
    return {
      users: [],
      sccfList: [],
      isLoading: false,
      error: "",
      showModal: false,
      isDeactivating: false,
      selectedUser: {},
      isActivating: false,
      showActivateModal: false,
    };
  },
  computed: {
    officer() {
      let user = this.$store.state.display.userStation;
      user.id ? sessionStorage.setItem('officer',JSON.stringify(user)) :
        user = JSON.parse(sessionStorage.getItem('officer'));
      return user
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.listUserRoles();
    })
  },
  methods: {
    displayUtcDate,
    formatStationDisplay({ station, line }, result = "") {
      if (!isEmpty(station)) {
        result = station;
        if (!isEmpty(line))
          result += ` (${line})`;
      }
      return result;
    },
    async listUserRoles() {
      this.isLoadingProfile = true
      let arr = {
        OfficerId: this.officer.id,
        skip: 0,
        take: 100,
        // Search: ''
      }
      const result = await listUserRoles(arr)
      if (result.success) {
        this.sccfList = get(result, 'payload.list', []);
      } else {
        this.error = result.payload
      }
      this.isLoadingProfile = false
    },
  },
};
</script>
<style lang="scss" scoped>
.AsomTable__FilterAndSelectContainer {
  display: none;
}
</style>